<template>
  <f7-page class="profile-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <ProfileNavigationComponent type="menu" :title="$t.getTranslation('LBL_PROFILE')" />

      <!-- <f7-fab v-if="!(['tabOrders', 'tabSaved', 'tabCoupons'].indexOf(selectedTab) > -1)" position="center-bottom" href="/post/create/">
        <font-awesome-icon :icon="['far', 'plus']" fixed-width />
      </f7-fab> -->
    </template>

    <section class="profile-container">
      <div class="container">
        <div class="profile-info">
          <div class="image">
            <img :src="$h.getImage(profileInfo.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" loading="lazy" />
            <f7-button href="/profile/settings/" fill small>
              <font-awesome-icon :icon="['far', 'edit']" fixed-width />
            </f7-button>
          </div>

          <div class="subcontent">
            <div class="counter">
              <div class="count" @click="viewFollows">
                <h3>
                  {{ $h.formatDisplayNumber(profileInfo?.UserCounter?.TotalFollow) }}
                </h3>
                <h4>
                  {{ $t.getTranslation("LBL_FOLLOWS") }}
                </h4>
              </div>
              <div class="count" @click="viewFollowers">
                <h3>
                  {{ $h.formatDisplayNumber(profileInfo?.UserCounter?.TotalFollower) }}
                </h3>
                <h4>
                  {{ $t.getTranslation("LBL_FOLLOWERS") }}
                </h4>
              </div>
              <div class="count" @click="viewCellar">
                <h3>
                  {{ $h.formatDisplayNumber(profileInfo?.UserCounter?.TotalBookmark) }}
                </h3>
                <h4>
                  {{ $t.getTranslation("LBL_FAVORITES") }}
                </h4>
              </div>
            </div>
            <div class="sub-info">
              <f7-button href="/profile/settings/account/" fill small>{{ $t.getTranslation("LBL_UPDATE") }}</f7-button>
              <div class="sub-info-status">
                <h4 v-if="profileInfo?.Influencer?.UserStatusCode == 'ACTIVE'">
                  {{ $t.getTranslation("LBL_INFLUENCER") }}
                </h4>
                <h4 v-if="profileInfo?.TotalUserOrdered?.Count > 0">
                  {{ $t.getTranslation("LBL_HONORED_MEMBER") }}
                </h4>
              </div>
              <h3 v-if="profileInfo?.TotalUserOrdered?.Count > 0">
                <img class="wine-icon" :src="require('@/assets/images/badge-small.png')" loading="lazy" />
              </h3>
              <h3 v-else>{{ $t.getTranslation("LBL_MEMBER") }}</h3>
            </div>
          </div>
        </div>

        <div class="profile-content">
          <div class="name">
            <h3>{{ $h.getFullName(profileInfo) }}</h3>
          </div>

          <!-- <p v-if="!profileInfo.IsKOL">
            {{
              $h.formatTrimString(profileInfo.Bio, 130) ||
              $t.getTranslation("LBL_BIO_EMPTY")
            }}
          </p> -->

          <p v-if="!profileInfo.IsKOL">{{ profileInfo.Bio || $t.getTranslation("LBL_BIO_EMPTY") }}</p>

          <!-- <div class="kol-card" v-else>
            <div class="card-head">{{ $t.getTranslation('LBL_KOL_POINTS') }}</div>
            <div class="points">
              <div style="display: flex;align-items: flex-end;">
                <span>{{ $h.formatNumber(profileInfo?.KOLPoints?.Available) }}</span>
                <span>{{ $t.getTranslation('LBL_AVAILABLE') }}</span>
              </div>

               <div style="display: flex;align-items: flex-end;">
                <span class="small">{{ $h.formatNumber(profileInfo?.KOLPoints?.Pending) }}</span>
                <span class="small">{{ $t.getTranslation('LBL_TENTATIVE') }}</span>
              </div>
            </div>
          </div> -->

          <ul v-if="profileInfo?.Keywords">
            <li v-for="keyword in profileInfo?.Keywords" :key="'ky_' + keyword">
              {{ keyword }}
            </li>
          </ul>
        </div>

        <!-- <div class="profile-content-container">
          <template v-if="selectedTab === 'tabPosts'">
            <PostListComponent v-if="selectedTab === 'tabPosts'" ref="postListComponent" mode="self" :size="12" />
          </template>

          <template v-if="selectedTab === 'tabOrders'">
            <template v-if="orderList && orderList.length > 0">
              <div v-for="order in orderList" :key="'ord_' + order.OrderKey">
                <OrderItemCardComponent :data="order" />
              </div>
            </template>

            <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_ORDER_EMPTY')" />
          </template>

          <template v-if="selectedTab === 'tabCoupons'">
            <template v-if="voucherList && voucherList.length > 0">
              <div v-for="item in voucherList" :key="'coupon_' + item.UserVoucherId">
                <VoucherCardComponent :data="item"></VoucherCardComponent>
              </div>
            </template>

            <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_NO_VALID_COUPON_FOUND')" />
          </template>
        </div> -->

        <!-- <div class="profile-tabs">
          <f7-toolbar tabbar class="tablinks">
            <f7-link tab-link="#tabPosts" :tab-link-active="selectedTab === 'tabPosts'" @click="changeTab('tabPosts')">{{ $t.getTranslation("LBL_POSTS") }}</f7-link>

            <f7-link tab-link="#tabSaved" :tab-link-active="selectedTab === 'tabSaved'" @click="changeTab('tabSaved')">{{ $t.getTranslation("LBL_LIKED") }}</f7-link>

            <f7-link tab-link="#tabCoupons" v-if="$configs.couponEnabled" :tab-link-active="selectedTab === 'tabCoupons'" @click="changeTab('tabCoupons')">{{ $t.getTranslation("LBL_COUPONS") }}</f7-link>

            <f7-link tab-link="#tabOrders" :tab-link-active="selectedTab === 'tabOrders'" @click="changeTab('tabOrders')">{{ $t.getTranslation("LBL_ORDERS") }}</f7-link>
          </f7-toolbar>

          <f7-tabs>
            <f7-tab id="tabPosts" class="page-content profile-posts-tab" :tab-active="selectedTab === 'tabPosts'">
              <PostListComponent v-if="selectedTab === 'tabPosts'" ref="postListComponent" mode="self" :size="12" />
            </f7-tab>

            <f7-tab id="tabSaved" class="page-content profile-saved-tab" :tab-active="selectedTab === 'tabSaved'">
              <PostListComponent v-if="selectedTab === 'tabSaved'" ref="postLikedComponent" mode="liked" :size="12" />
            </f7-tab>

            <f7-tab id="tabCoupons" v-if="$configs.couponEnabled" class="page-content profile-coupons-tab" :tab-active="selectedTab === 'tabCoupons'">
              <template v-if="voucherList && voucherList.length > 0">
                <div v-for="item in voucherList" :key="'coupon_' + item.UserVoucherId">
                  <VoucherCardComponent :data="item"></VoucherCardComponent>
                </div>
              </template>

              <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_NO_VALID_COUPON_FOUND')" />
            </f7-tab>

            <f7-tab id="tabOrders" class="page-content profile-orders-tab" :tab-active="selectedTab === 'tabOrders'">
              <f7-toolbar tabbar class="tablinks">
                <f7-link :tab-link-active="orderMode == 'all'" @click="changeOrderMode('all')">{{ $t.getTranslation("LBL_ALL") }}</f7-link>
                <f7-link :tab-link-active="orderMode == 'pay'" @click="changeOrderMode('pay')">{{ $t.getTranslation("LBL_TO_PAY") }}</f7-link>
                <f7-link :tab-link-active="orderMode == 'receive'" @click="changeOrderMode('receive')">{{ $t.getTranslation("LBL_TO_RECEIVE") }}</f7-link>
                <f7-link :tab-link-active="orderMode == 'review'" @click="changeOrderMode('review')">{{ $t.getTranslation("LBL_TO_REVIEW") }}</f7-link>
              </f7-toolbar>

              <template v-if="orderList && orderList.length > 0">
                <div v-for="order in orderList" :key="'ord_' + order.OrderKey">
                  <OrderItemCardComponent :data="order" />
                </div>
              </template>

              <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_ORDER_EMPTY')" />
            </f7-tab>
          </f7-tabs>
        </div> -->
      </div>
    </section>

    <div class="profile-link-container">
      <div class="profile-link">
        <h3>{{ $t.getTranslation("LBL_MY_ORDER") }}</h3>
        <ul>
          <li @click="goToPageName('profileOrderPage', 'all')">
            <a href="#">
              <font-awesome-icon class="order-btn" :icon="['far', 'th-list']" fixed-width />
              <span>{{ $t.getTranslation("LBL_ALL_ORDERS") }}</span>
            </a>
          </li>
          <li @click="goToPageName('profileOrderPage', 'pay')">
            <a href="#">
              <font-awesome-icon class="order-btn" :icon="['far', 'money-check-edit-alt']" fixed-width />
              <span>{{ $t.getTranslation("LBL_TO_PAY") }}</span>
            </a>
          </li>
          <li @click="goToPageName('profileOrderPage', 'dispatch')">
            <a href="#">
              <font-awesome-icon class="order-btn" :icon="['far', 'shipping-fast']" fixed-width />
              <span>{{ $t.getTranslation("LBL_TO_DISPATCH") }}</span>
            </a>
          </li>
          <li @click="goToPageName('profileOrderPage', 'receive')">
            <a href="#">
              <font-awesome-icon class="order-btn" :icon="['far', 'box-check']" fixed-width />
              <span>{{ $t.getTranslation("LBL_DELIVERED") }}</span>
            </a>
          </li>
          <li @click="goToPageName('profileOrderPage', 'review')">
            <a href="#">
              <font-awesome-icon class="order-btn" :icon="['far', 'comments']" fixed-width />
              <span>{{ $t.getTranslation("LBL_TO_COMMENT") }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="profile-link">
        <h3>{{ $t.getTranslation("LBL_MY_RECORDS") }}</h3>
        <ul>
          <li @click="goToPageName('profilePostPage')">
            <a href="#">
              <font-awesome-icon class="order-btn-con order-btn-post" :icon="['far', 'copy']" fixed-width />
              <span>{{ $t.getTranslation("LBL_POST") }}</span>
            </a>
          </li>
          <li @click="goToPageName('cellarListPage')">
            <a href="#">
              <font-awesome-icon class="order-btn-con order-btn-tast" :icon="['far', 'wine-glass']" fixed-width />
              <span>{{ $t.getTranslation("LBL_MY_CELLAR") }}</span>
            </a>
          </li>
          <li @click="goToPageName('profileCouponPage')">
            <a href="#">
              <font-awesome-icon class="order-btn-con order-btn-coup" :icon="['far', 'ticket']" fixed-width />
              <span>{{ $t.getTranslation("LBL_COUPON") }}</span>
            </a>
          </li>
          <li @click="goToPageName('cartPage')">
            <a href="#">
              <font-awesome-icon class="order-btn-con order-btn-cart" :icon="['far', 'shopping-cart']" fixed-width />
              <span>{{ $t.getTranslation("LBL_MY_CART") }}</span>
            </a>
          </li>
          <li @click="goToPageName('profileAddressPage')">
            <a href="#">
              <font-awesome-icon class="order-btn-con order-btn-myad" :icon="['far', 'map-marked']" fixed-width />
              <span>{{ $t.getTranslation("LBL_MY_ADDRESS") }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="profile-link">
        <h3>{{ $t.getTranslation("LBL_TOOLS") }}</h3>
        <ul>
          <li @click="goToPageName('profileUserGuidePage')">
            <a href="#">
              <font-awesome-icon class="order-btn-con order-btn-term" :icon="['far', 'anchor']" fixed-width />
              <span>{{ $t.getTranslation("LBL_TERMS_CONDITIONS") }}</span>
            </a>
          </li>
          <li @click="goToPageName('chat')">
            <a href="#">
              <font-awesome-icon class="order-btn-con order-btn-live" :icon="['far', 'headset']" fixed-width />
              <span>{{ $t.getTranslation("LBL_LIVE_CHAT") }}</span>
            </a>
          </li>
          <!-- <li @click="goToPageName('tips')">
            <a href="#">
              <font-awesome-icon class="order-btn-live" :icon="['far', 'lightbulb-on']" fixed-width />
              <span>{{ $t.getTranslation("LBL_USER_TIPS") }}</span>
            </a>
          </li> -->
          <li @click="goToPageName('merchantContactpage')">
            <a href="#">
              <font-awesome-icon class="order-btn-con order-btn-merc" :icon="['far', 'users']" fixed-width />
              <span>{{ $t.getTranslation("LBL_MERCHANT_CENTER") }}</span>
            </a>
          </li>
          <li @click="goToPageName('contact')">
            <a href="#">
              <font-awesome-icon class="order-btn-con order-btn-cont" :icon="['far', 'stars']" fixed-width />
              <span>{{ $t.getTranslation("LBL_CONTACT_US") }}</span>
            </a>
          </li>
          <li @click="chatConcierge()">
            <a href="#">
              <img class="order-btn-con order-btn-conc" :src="require('@/assets/images/concierge-small.png')" loading="lazy" />
              <span>{{ $t.getTranslation("LBL_CONCIERGE_SERVICE") }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="profile-link">
        <h3>{{ $t.getTranslation("LBL_MY_ACTIVITIES") }}</h3>
        <ul>
          <li @click="goToPageName('profileReferralMainPage')">
            <a href="#">
              <font-awesome-icon class="order-btn-con order-btn-cont" :icon="['far', 'users']" fixed-width />
              <span>{{ $t.getTranslation("LBL_REFERRAL") }}</span>
            </a>
          </li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, ref, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";
// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));

// import OrderItemCardComponent from "@/components/cards/OrderItemCardComponent.vue";
// import PostListComponent from "@/components/PostListComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";
// import VoucherCardComponent from "@/components/cards/VoucherCardComponent.vue";

export default defineComponent({
  name: "ProfilePage",
  components: {
    ProfileNavigationComponent,
    // OrderItemCardComponent,
    // PostListComponent,
    // NoDataFoundComponent,
    // VoucherCardComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const selectedTab = ref();
    const productList = ref([]);
    const orderList = ref([]);
    const voucherList = ref([]);

    const profileInfo = ref(false);

    const postListComponent = ref(false);
    const postLikedComponent = ref(false);
    const showPreloader = ref(false);
    let isGettingList = ref(true);

    const store = useStore();

    const orderMode = ref("all");
    const changeOrderMode = (mode) => {
      orderMode.value = mode;
      getOrders();
    };

    const getProductList = async () => {
      let ret = await get("/wishlist/view", {});
      isGettingList.value = false;
      productList.value = ret?.Wishlist?.ItemList;
    };

    const getOrders = async () => {
      let filt = orderMode.value == "all" ? "" : orderMode.value;
      let ret = await get("/order/list", { filter: filt });
      orderList.value = ret?.List;
    };

    const getCoupons = async () => {
      let ret = await get("/user/coupon", {});
      voucherList.value = ret?.List;
    };

    const getProfileInfo = async () => {
      profileInfo.value = await get("/user/info");

      if (props?.f7route?.query?.orders) {
        selectedTab.value = "tabOrders";
      } else if (props?.f7route?.query?.posts) {
        selectedTab.value = "tabPosts";
      } else if (props?.f7route?.query?.coupons) {
        selectedTab.value = "tabCoupons";
      } else {
        selectedTab.value = "tabPosts";
      }
    };

    const changeTab = (tab, orderMode) => {
      selectedTab.value = tab;

      if (tab == "tabOrders") {
        //route replace?????
        props.f7router.navigate({ name: "profilePage", query: { orders: 1, mode: orderMode } }, { reloadCurrent: true });
      } else if (tab == "tabPosts") {
        //route replace?????
        props.f7router.navigate({ name: "profilePage", query: { posts: 1 } }, { reloadCurrent: true });
      } else if (tab == "tabCoupons") {
        //route replace?????
        props.f7router.navigate({ name: "profilePage", query: { coupons: 1 } }, { reloadCurrent: true });
      } else {
        //route replace?????
        props.f7router.navigate({ name: "profilePage", query: { tabSaved: 1 } }, { reloadCurrent: true });
      }
    };

    const viewFollows = (tab) => {
      props.f7router.navigate({
        name: "profileUserListPage",
        params: { mode: "follows" },
      });
    };

    const viewFollowers = (tab) => {
      props.f7router.navigate({
        name: "profileUserListPage",
        params: { mode: "followers" },
      });
    };

    const viewCellar = (tab) => {
      props.f7router.navigate({ name: "cellarListPage" });
    };

    const goToPageName = (pageName, mode) => {
      if (mode) return props.f7router.navigate({ name: pageName, query: { mode } });
      props.f7router.navigate({ name: pageName });
    };

    const chatConcierge = () => {
      //add store dispatch here..
      store.dispatch("user/setData", {
        IsConcierge: 1,
      });
      goToPageName("chat");
    };

    onMounted(() => {
      if (props?.f7route?.query?.orders && props?.f7route?.query?.mode) {
        orderMode.value = props?.f7route?.query?.mode;
      }

      getProfileInfo();
      getProductList();
      getOrders();
      getCoupons();
    });

    return {
      changeOrderMode,
      orderMode,
      voucherList,
      profileInfo,
      orderList,
      productList,
      isGettingList,
      postListComponent,
      postLikedComponent,
      showPreloader,
      changeTab,
      selectedTab,
      viewFollows,
      viewFollowers,
      viewCellar,
      goToPageName,
      chatConcierge,
    };
  },
  methods: {
    async loadMore() {
      this.showPreloader = true;
      if (this?.$refs?.postListComponent) await this.$refs.postListComponent.loadMore();
      if (this?.$refs?.postLikedComponent) await this.$refs.postLikedComponent.loadMore();
      this.showPreloader = false;
    },
  },
});
</script>

<style scoped>
.profile-saved-tab {
  margin-top: -15px !important;
}

.card-head {
  color: #fff;
  font-size: 12px;
}
.kol-card {
  background: rgb(38, 106, 204);
  background: linear-gradient(90deg, rgba(38, 106, 204, 1) 0%, rgba(19, 76, 158, 1) 100%);
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.kol-card .points {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.kol-card .points span {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  margin-left: 8px;
}

.kol-card .points span:first-child {
  font-size: 23px;
  color: #fff;
  font-weight: 200;
  line-height: 23px;
}

.kol-card .points span.small {
  font-size: 13px;
  line-height: 13px;
  margin-top: 10px;
  font-weight: 300;
  opacity: 0.8;
}

.sub-info {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.order-btn {
  padding: 12px;
  background-color: #0c4d8f;
  border-radius: 10px;
  color: #fff;
}
.order-btn-con {
  padding: 15px;
  width: 25px;
  height: 25px;
}
.order-btn-post {
  background-image: linear-gradient(#3e64ff60, transparent);
  border-radius: 100%;
  color: #3e64ff;
}
.order-btn-tast {
  background-image: linear-gradient(#ff005c60, transparent);
  border-radius: 100%;
  color: #ff005c;
}
.order-btn-coup {
  background-image: linear-gradient(#f14a1660, transparent);
  border-radius: 100%;
  color: #f14a16;
}
.order-btn-cart {
  background-image: linear-gradient(#fc991860, transparent);
  border-radius: 100%;
  color: #fc9918;
}
.order-btn-myad {
  background-image: linear-gradient(#ffd50060, transparent);
  border-radius: 100%;
  color: #ffd500;
}
.order-btn-term {
  background-image: linear-gradient(#42dee160, transparent);
  border-radius: 100%;
  color: #42dee1;
}
.order-btn-live {
  background-image: linear-gradient(#e4007c60, transparent);
  border-radius: 100%;
  color: #e4007c;
}
.order-btn-merc {
  background-image: linear-gradient(#6807f960, transparent);
  border-radius: 100%;
  color: #6807f9;
}
.order-btn-cont {
  background-image: linear-gradient(#85ef4760, transparent);
  border-radius: 100%;
  color: #85ef47;
}
.order-btn-conc {
  background-image: linear-gradient(#dc2ade60, transparent);
  border-radius: 100%;
  color: #dc2ade;
  margin-top: 5px;
  margin-bottom: 12px;
  width: 25px;
  height: 25px;
}
</style>
